import React from 'react';
import styles from './notice.module.css';
import {announcementInfo} from '../api/index';
import backWUrl from '../img/backW.png';
import backYUrl from '../img/backY.png';

class NoticeDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         contentObj:{},
         type:2,
        };
      }

      announcementInfo=(id)=>{
        announcementInfo({id}).then(res=>{
            this.setState({contentObj:res})
          })
        }
        back=()=>{
            // this.props.history.goBack()
            window.history.back();
        }
        goMark=(e,mark)=>{ 
            if(mark===1){
              this.props.history.push({ pathname: '/' });
            }else{
              this.props.history.push({ pathname: '/',state: { mark}  });
            }
        }
    componentDidMount() {
        if(this.props.location.state){
            if(this.props.location.state.id){
                this.setState({type:this.props.location.state.type})
                this.announcementInfo(this.props.location.state.id)
            }
        }
    }

    render(){
        const{contentObj,type}=this.state
        return(
            <div className={styles.content}>
                <div className={styles.top} style={{backgroundImage:`url(${type===1?backYUrl:backWUrl})`}}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <div className={styles.headerContentTitle}>
                            <a onClick={(e)=>{this.goMark(e,1)}}>首页</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                            <span>/</span>
                            </div>
                    </div>
                </div>
                    <img className={styles.titleImg} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/b2c8060fd4084f64b5a498dbf6df229a.png' alt=''></img>
                    <div className={styles.empty}></div>
                </div>
                <div className={styles.detail}>
                    <div className={styles.detailTitle}>
                        <div className={styles.detailTitleLeft} onClick={()=>{this.back()}}>
                            <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/98245502259b4bed89d3b411fc91ee03.png'></img>
                        </div>
                        <div className={styles.detailTitleRight}>
                            <div>{contentObj.title}</div>
                            <div>{contentObj.date}</div>
                        </div>
                        
                    </div>
                    
                    <div className={styles.detailContent} dangerouslySetInnerHTML = {{ __html: contentObj.content}} />
                </div>
                {/* 手机屏幕显示 */}
                <div className={styles.headerPh}>
                    <div>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <img onClick={(e)=>{this.goMark(e,1)}} className={styles.homeImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210910/3eaec71c4d3944acaaee4cc4a3a23a28.png'></img>
                    </div>
                </div>
                <div className={styles.detailPh}>
                    <div className={styles.detailTitlePh}>
                        <img alt='' onClick={()=>{this.back()}} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/98245502259b4bed89d3b411fc91ee03.png'></img>
                        <div>公告中心</div>
                    </div>
                    <div className={styles.detailMainPh}>
                        <div className={styles.detailMainTitlePh}>{contentObj.title}</div>
                        <div className={styles.detailMainDatePh}>{contentObj.date}</div>
                        <div className={styles.detailContentPh} dangerouslySetInnerHTML = {{ __html: contentObj.content}} />
                    </div>
                </div>
                
            </div>
        )
    }
  

}

export default NoticeDetail;