import React from 'react';
import './home.css';
import bannerUrl from '../img/banner.png';
import {introduceList,announcementList ,introduceInfo} from '../api/index';
import { urlencoded } from 'body-parser';

const wuPart=[
  {
    indexImg:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/855bdd6206be4c68b6734fc19ceb2ed3.png',
    title:'游戏规则',
  },
  {
    indexImg:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/2cac5e2412b546e1b2e19c316c9ef8e2.png',
    title:'新手引导',
  },
  {
    indexImg:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/8c03fbf3bbac40eaa1c8d1854dfdc149.png',
    title:'恶人谷',
  },
  {
    indexImg:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/d8f19bca9b644712a1a7864322b51f5c.png',
    title:'矿山保卫战',
  },
]
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeList:[],  //最新公告
      storyList:[], //小说
      yList:[],
      wList:[],
      activeList:[],//热门活动
      storyType:3,
      downLoadType:1,
      flag:false,
      flagW:false,
    };
  }
  goMark=(e,id)=>{
    console.log('id',id)
    if(e!==1){
      e.preventDefault();
    }
    let element = document.getElementById(id);
    element.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
  clickStory=(storyType)=>{
    this.setState({storyType,storyList:[]});
    this.announcementList(storyType);
  }
  //公告查看更多
  noticeMore=()=>{
    this.props.history.push({ pathname: '/noticeMore'}); 
  }

  goNoticeDetail=(id,type)=>{
    this.props.history.push({ pathname: '/noticeDetail',state: { id,type}  });
  }  
  //公司介绍
  goIntroduce=(type)=>{
    this.props.history.push({ pathname: '/partIntroduce',state: { type}});
  }
  //部分介绍
  goPartIntroduce=(id,indexTitle,flag)=>{
    if(indexTitle===5){
      introduceInfo({id}).then(res=>{
        if(res.content=='<p><br></p>'){
          console.log('0000')
          return
        }else{
          this.props.history.push({ pathname: '/partIntroduce',state: {id,indexTitle,flag}});
        }
      })
    }else{
      this.props.history.push({ pathname: '/partIntroduce',state: {id,indexTitle,flag}});
    }
    
  }
  clickDownY=()=>{
    if(document.body.clientWidth>910){
      this.setState({flag:!this.state.flag})
    }else if(document.body.clientWidth<910){
      window.location.href = 'http://creats.tuanxo.com/#/'
    }
  }
  clickDownW=()=>{
    if(document.body.clientWidth>910){
      this.setState({flagW:!this.state.flagW})
    }else if(document.body.clientWidth<910){
      let id=this.state.wList[1].id
      this.goPartIntroduce(id,2,false)
    }
    
  }
  clickDownLoad=(downLoadType)=>{
    this.setState({downLoadType})
  }
  //小说查看更多
  goStoryMore=()=>{
    this.props.history.push({ pathname: '/storyMore',state: {type:this.state.storyType}});
  }
  goStoryDetail=(id)=>{
    this.props.history.push({ pathname: '/storyDetail',state: { id,type:this.state.storyType}  });
  }

  getIntroduceList=()=>{
    let yList=[];
    let wList=[];
    let activeList=[];
    introduceList().then(res=>{
        res.forEach(item => {
          if(item.type===1){
            yList.push(item)
          }else if(item.type===2){
            wList.push(item)
          }else if(item.type===3){
            activeList.push(item)
          }
        });
        for(let i in wList){
          wList[i].title=wuPart[i].title;
          wList[i].indexImg=wuPart[i].indexImg;
        }
        this.setState({yList,wList,activeList})
      });
      
    }
    announcementList=(type)=>{

      announcementList({type}).then(res=>{
          if(type===0){
            this.setState({noticeList:res})
          }else{
            this.setState({storyList:res})
          }
        })
      }
      // getWidth=()=>{
      //   window.onresize = () => {
      //     return (() => {
      //       let browserWidth = document.body.clientWidth;
      //       let browserHeight = document.body.clientHeight;
      //       console.log("宽--", browserWidth, "    ", "高--", browserHeight);
      //     })();
      //   };
      // }
      
  
  componentDidMount() {
   this.getIntroduceList();
   this.announcementList(0);
   this.announcementList(3);
   let lo=this.props.location
   if(lo.state){
      if(lo.state.mark){
        this.goMark(1,lo.state.mark)
      }
   }
    
  }
  render() {
    const {yList,wList,activeList,noticeList,storyList,storyType,downLoadType,flag,flagW}=this.state
    
    return(
      <div className='content'>
        <div className='top' style={{backgroundImage:`url(${bannerUrl})`}}>
          <div className='header'>
              <div className='headerContent'>
                <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                <div className='headerContentTitle'>
                  <a>首页</a>
                  <span>/</span>
                  <a href="#mark1" onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                  <span>/</span>
                  <a href="#mark2" onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                  <span>/</span>
                  <a href="#mark3" onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                  <span>/</span>
                </div>
              </div>
          </div>
          <div className='topImg'><img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210909/93a1c2dbe5284b0a846cc3c98e876cb2.png'></img></div>
          <div className='top-content'>
            <div className='notice'>
              <div className='noticeTitle'>
                <div>公告中心</div>
                <div onClick={()=>{this.noticeMore()}}>查看更多</div>
              </div>
              
              <div className='noticeContent'>
                 {noticeList.map( (item,index)  => 
                  <div key={index} className='noticeContentItem' onClick={()=>{this.goNoticeDetail(item.id,item.type)}}>
                    <div>{item.title}</div>
                    <div>[{item.date}]</div>
                  </div>
                  )}
              </div>
              
            </div>
            <div className='yIntroduce' onClick={()=>{this.goIntroduce(1)}}>
              <img alt='尤礼介绍' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/5bdd7ca76c5f4b02ae385a09827d9bb2.png' />
            </div>
            <div className='wIntroduce' onClick={()=>{this.goIntroduce(2)}}>
              <img alt='五行介绍'  src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/bba189c641ba4efb82bea23f12a1f9e5.png' />
            </div>
          </div>
         
        </div>
        <div className='main'>

          <div className='downLoad'>
            <div className='downLoadTitle' onClick={()=>{this.clickDownW()}}>前往五行江湖</div>
            <div className='downLoadTitle' onClick={()=>{this.clickDownY()}}>下载尤礼</div>
            <div className='downLoadCodeW' style={{display:flagW?'block':'none'}}>
              <img alt='code' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210912/fa5dc33421a64f22a0f5b0d84094b76e.jpeg'></img>
            </div>
            <div className='downLoadCode' style={{display:flag?'block':'none'}}>
                <img alt='code' src={downLoadType===1?'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/b0190b9b517c4bd28accd24467fe4f2d.png':'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/7a2eb447bf86407fa8d0b5150ec774aa.jpg'}></img>
                <div className='downLoadCodeButton'>
                    <div className='downLoadCodeButton1' style={{backgroundImage:`url(${downLoadType===1?'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/8b88a908513b40a69d833c3ddb2e9b89.png':'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/a9f4c165bf3449b9abc033d9158c17db.png'})`}} onClick={()=>{this.clickDownLoad(1)}}>下载APP</div>
                    <div className='downLoadCodeButton2' style={{backgroundImage:`url(${downLoadType===2?'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/8fea80d2bbd04a1fa8cc1014a7e49a3d.png':'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/3349fdf711124c0a8d60c5ca66844d67.png'})`}} onClick={()=>{this.clickDownLoad(2)}}>微信小程序</div>
                </div>
            </div>
          </div>
          
          <div className='wu'>
            <div className='wuTitle' id='mark1'>五行江湖<span>国风链游</span></div>
            <div className='wuPart'>
              {wList.map( (item,index)  => 
              <div key={index} style={{backgroundImage:`url(${item.img})`}} className='partItem' onClick={()=>{this.goPartIntroduce(item.id,index+1,false)}}>
                <div className='partItemIn'>
                  <img alt='背景' src={item.indexImg} />
                  <div className='partItemIn-div1'>五行江湖</div>
                  <div className='partItemIn-div2'>{item.title}</div>
                  <div className='partItemIn-div3'>查看更多</div>
                </div>
              </div> )}

            </div>
          </div>
          
          <div className='storyActivity'>
            <div className='story'>
              <div className=''>五行江湖</div>
              <div className='line'></div>
              <img className={storyType===3?'checkLine':'checkLine4'} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210901/a835fc77acc34eeaad4b46c44062c32b.png' alt=''></img>
              <div className='storyText'>
                <div className='storyText-div'>
                  <span className={storyType===3?'storyType':''} onClick={()=>{this.clickStory(3)}}>小蝠说</span>
                  <span className={storyType===4?'storyType':''} onClick={()=>{this.clickStory(4)}}>戏中戏</span>
                </div>
                <div className='storyMore' onClick={()=>{this.goStoryMore()}}>查看更多</div>  
              </div>
              <div className='storyImg'>
              {storyList.map( (item,index)  => <div className='storyImgItem' key={index}>
              <img  alt='' src={item.img} onClick={()=>{this.goStoryDetail(item.id)}}/></div>)}
              </div>
            </div>

            <div className='activity'>
              <div className='activityTitle'>热门活动</div>
              {activeList.map( (item,index)  => 
              <img key={index} alt='' src={item.img} onClick={()=>{this.goPartIntroduce(item.id,5,false)}}/>)}
            </div>

          </div>

          <div className='yIntroducePart'>
              <div className='yIntroducePartTitle' id='mark2'>尤礼，<span>不一样的送礼平台</span></div>
              <div className='yIntroducePartImg'>
              {yList.map( (item,index)  => <div className='yIntroducePartImgItem' key={index}>
              <img alt='' src={item.img} onClick={()=>{this.goPartIntroduce(item.id,index+1,true)}}/></div>)}
              </div>
          </div>

          <div className='attention'>
            <div className='attentionTitle' id='mark3'>
              <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/2fb6e0515aa34d00a073b7e3d12d28e2.png'></img>
              <span>关注我们</span>
              <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/588f1314fcff4d5a84a13d525f98798f.png'></img>
            </div>
            <div className='attentionContent'>
              <div className='attentionW'>
                <div className='service'>
                  <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/334a15edc42e48ac805e20a80e11928c.png'></img>
                  <span>五行江湖客服</span>
                </div>
                <div className='infoW'>
                  <div>微信：FOMO55555</div>
                  <div>Twitter：@AURA_Superb</div>
                  <div>Telegram：@FOMO55555</div>
                  <div>Telegram group：<span>https://t.me/fomo555</span></div>
                  <div>审计报告地址：<span>https://www.certik.org/projects/aura</span></div>
                </div>
              </div>
              <div className='attentionW'>
                <div className='service'>
                  <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/334a15edc42e48ac805e20a80e11928c.png'></img>
                  <span>尤礼客服</span>
                </div>
                <div className='infoW'>
                  <div>客服（菲菲）微信：13157868170</div>
                  <div>微信小程序：XO尤礼</div>
                  <div>微信公众号：XO-youli</div>
                  <div>微博：尤礼官微</div>
                  <div>官方邮箱：<span>manwukeji123@163.com</span></div>
                </div>
              </div>
            </div>
          </div>

          <div className='copyright'>
          COPYRIGHT © 2021 MANWU ALL RIGHTS RESERVED.
          </div>

        </div>
      </div>
    )
  }
}
export default  Home;