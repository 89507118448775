import React from 'react';
import styles from './notice.module.css';
import {announcementMore} from '../api/index';
import backWUrl from '../img/backW.png';
import backYUrl from '../img/backY.png';

class NoticeMore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         list:[],
         type:0,
        };
      }
      goDetail=(id,type)=>{
        this.props.history.push({ pathname: '/noticeDetail',state: { id,type}  });
      }
      goMore=(type)=>{
        this.setState({type});
        this.announcementMore(type);
      }
      
      goMark=(e,mark)=>{ 
          if(mark===1){
            this.props.history.push({ pathname: '/' });
          }else{
            this.props.history.push({ pathname: '/',state: { mark}  });
          }
         
      }
      announcementMore=(type)=>{
        announcementMore({type}).then(res=>{
            this.setState({list:res})
          })
        }
    componentDidMount() {
       this.announcementMore(0)
    }

    render(){
        const {list,type}=this.state;
        return(
            <div className={styles.content}>
                <div className={styles.top} style={{backgroundImage:`url(${type===1?backYUrl:backWUrl})`}}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <div className={styles.headerContentTitle}>
                            <a onClick={(e)=>{this.goMark(e,1)}} >首页</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                            <span>/</span>
                            </div>
                    </div>
                </div>
                    <img className={styles.titleImg} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/b2c8060fd4084f64b5a498dbf6df229a.png' alt=''></img>
                    <div className={styles.empty}></div>
                </div>
                <div className={styles.main}>
                    <div className={styles.currentPlace}>
                        <div className={styles.currentPlaceDiv1} onClick={(e)=>{this.goMark(e,1)}}>
                            <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/a8a48c333e5049c6ab98ae337118823a.png'></img>
                            <span>您所在位置：官网首页&gt;公告中心</span>
                        </div>
                        <div className={styles.currentPlaceDiv2}>
                            <div onClick={()=>{this.goMore(0)}} >
                                <span className={type===0?styles.currentSpan:''}>最新</span>
                                <img className={type===0?styles.currentImg:styles.noImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/e0d4073968584728b8fcf1a163bc5b85.png'></img>
                            </div>
                            <div onClick={()=>{this.goMore(2)}}>
                                <span className={type===2?styles.currentSpan:''}>五行江湖</span>
                                <img className={type===2?styles.currentImg:styles.noImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/e0d4073968584728b8fcf1a163bc5b85.png'></img>
                            </div>
                            <div onClick={()=>{this.goMore(1)}}>
                                <span className={type===1?styles.currentSpan:''}>尤礼</span>
                                <img className={type===1?styles.currentImg:styles.noImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/e0d4073968584728b8fcf1a163bc5b85.png'></img>
                            </div>
                           
                        </div>

                    </div>
                    <div className={styles.noticeList}>
                    {list.map((item,index)=><div key={item.id} className={styles.noticeItemHover}>
                        <div className={index+1===list.length?styles.noborder:styles.noticeItem }>
                        <div className={styles.listLeft}>
                            <img alt='' style={{display:item.type===2?'block':'none'}} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/f47afc38a52641ccba0d07f2fa5e010d.png'></img>
                            <img alt='' style={{display:item.type===1?'block':'none'}} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/729a75fa66df43dbbd41625478cbbc64.png'></img>
                            <div className={styles.listDate}>{item.date}</div>
                        </div>
                        <div onClick={()=>{this.goDetail(item.id,item.type)}} className={styles.listRight}>
                            <div className={styles.listTitle}>{item.title}</div>
                            <p> {item.summary}</p>
                        </div>
                        </div>
                    </div>)}
                    </div>
                </div>

            <div className={styles.headerPh}>
                <div>
                    <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                    <img className={styles.homeImg} onClick={(e)=>{this.goMark(e,1)}} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210910/3eaec71c4d3944acaaee4cc4a3a23a28.png'></img>
                </div>
                
            </div>
            <div className={styles.mainPh}>
                <div className={styles.mainTitle}>公告中心</div>
                <div className={styles.mainBanner}>
                    <div onClick={()=>{this.goMore(0)}} className={type===0?styles.current1:''}>最新</div>
                    <div onClick={()=>{this.goMore(2)}} className={type===2?styles.current:''}>五行江湖</div>
                    <div onClick={()=>{this.goMore(1)}} className={type===1?styles.current:''}>尤礼</div>
                </div>
                <div className={styles.noticeListPh}>
                    {list.map((item,index)=><div key={item.id} className={styles.noticeItemPh} onClick={()=>{this.goDetail(item.id,item.type)}}>
                        <div className={styles.itemTop}>
                            <img alt='' style={{display:item.type===2?'block':'none'}} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/f47afc38a52641ccba0d07f2fa5e010d.png'></img>
                            <img alt='' style={{display:item.type===1?'block':'none'}} src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/729a75fa66df43dbbd41625478cbbc64.png'></img>
                            <div>
                                <div className={styles.itemTopTitle}>{item.title}</div>
                                <div className={styles.itemTopDate}>{item.date}</div>
                            </div>  
                        </div>
                        <div className={styles.itemBottom}>
                            <p> {item.summary}</p>
                        </div>
                        
                    </div>)}
                    </div>
            </div>
                
        </div>
        )
    }
  

}

export default NoticeMore;