import React from 'react';
import styles from './story.module.css';
import backWUrl from '../img/backW.png';
import {announcementMore} from '../api/index';

class StoryMore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         list:[],
         type:3,
        };
      }
      goDetail=(id)=>{
        this.props.history.push({ pathname: '/storyDetail',state: { id,type:this.state.type}  });
      }
      goMark=(e,mark)=>{ 
        if(mark===1){
          this.props.history.push({ pathname: '/' });
        }else{
          this.props.history.push({ pathname: '/',state: { mark}  });
        }
    }
      announcementMore=(type)=>{
        announcementMore({type}).then(res=>{
            this.setState({list:res,type})
          })
        }
    componentDidMount() {
        if(this.props.location.state){
            if(this.props.location.state.type){
                this.announcementMore(this.props.location.state.type)
            }
        }
       
    }

    render(){
        const {list,type}=this.state;
        return(
            <div className={styles.content}>

                <div className={styles.top} style={{backgroundImage:`url(${backWUrl})`}}>
                    <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <div className={styles.headerContentTitle}>
                            <a onClick={(e)=>{this.goMark(e,1)}}>首页</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                            <span>/</span>
                            </div>
                    </div>
                </div>
                    <img className={styles.titleImg} src={type===3?'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/430357db614545c8b700a7a7e7ce06ba.png':'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/4315e39dd2b84ed891bb12d5950230e3.png'} alt=''></img>
                    <div className={styles.empty}></div>
                </div>
                {/* 手机 */}
                <div className={styles.headerPh}>
                <div>
                    <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                    <img onClick={(e)=>{this.goMark(e,1)}} className={styles.homeImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210910/3eaec71c4d3944acaaee4cc4a3a23a28.png'></img>
                </div>
                
                </div>
                <div className={styles.detail}>
                    <div className={styles.detailTitle} onClick={(e)=>{this.goMark(e,1)}}>
                        <img src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/a8a48c333e5049c6ab98ae337118823a.png' alt=''></img>
                        <span>您所在位置：官网首页&gt;{type===3?'小蝠说':'戏中戏'}</span>
                    </div>
                    <div className={styles.detailTitlePh}>{type===3?'小蝠说':'戏中戏'}</div>
                    <div className={styles.main}>
                        {list.map((item,index)=><div className={styles.mainItem}>
                            <img key={index} alt='' src={item.img} onClick={()=>{this.goDetail(item.id)}}></img>
                        </div>)}
                    </div>
                    
                </div>
                
            </div>
        )
    }
  

}

export default StoryMore;