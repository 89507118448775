import HttpClient from '../request/httpClient';

//尤礼和五行江湖图文介绍
export function introduceList(params) {
    return HttpClient.post('/front/website/introduceList', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }

  //查看详情--公告和文章
  export function announcementInfo(params) {
    return HttpClient.post('/front/website/announcementInfo', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }

  //首页公告和文章
  export function announcementList(params) {
    return HttpClient.post('/front/website/announcementList', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }

  //查看更多--公告和文章
  export function announcementMore(params) {
    return HttpClient.post('/front/website/announcementMore', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }

  //首页尤礼和五行详情信息 1-尤礼 2-五行
  export function getSettingInfo(params) {
    return HttpClient.post('/front/website/getSettingInfo', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }

   //查看详情--图文介绍
   export function introduceInfo(params) {
    return HttpClient.post('/front/website/introduceInfo', params, {
      contentType: 'application/x-www-form-urlencoded',
    })
  }


  