import React from 'react';
import styles from './story.module.css';
import { Carousel } from 'antd';
import {announcementInfo} from '../api/index';
import backWUrl from '../img/backW.png'

class StoryDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         contentObj:{},
         imgList:[],
         type:3,
        };
      }
      back=()=>{
        window.history.back()
    }
    handlePrev = ()=>{
	    this.refs.img.prev(); //ref = img
	}

    handleNext = ()=>{
        this.refs.img.next();
    }
    goMark=(e,mark)=>{ 
        if(mark===1){
          this.props.history.push({ pathname: '/' });
        }else{
          this.props.history.push({ pathname: '/',state: { mark}  });
        }
    }
      announcementInfo=(id)=>{
        announcementInfo({id}).then(res=>{
            this.setState({contentObj:res,imgList:(res.content).split(',')})
          })
        }
    componentDidMount() {
        if(this.props.location.state){
            if(this.props.location.state.type){
                this.setState({type:this.props.location.state.type})
                this.announcementInfo(this.props.location.state.id)
            }
        }
        
    }

    render(){
        const{contentObj,imgList,type}=this.state
        return(
            <div className={styles.content}>
                <div className={styles.top} style={{backgroundImage:`url(${backWUrl})`}}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <div className={styles.headerContentTitle}>
                            <a onClick={(e)=>{this.goMark(e,1)}}>首页</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                            <span>/</span>
                            </div>
                    </div>
                </div>
                    <img className={styles.titleImg} src={type===3?'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/430357db614545c8b700a7a7e7ce06ba.png':'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/4315e39dd2b84ed891bb12d5950230e3.png'} alt=''></img>
                    <div className={styles.empty}></div>
                </div>
                 {/* 手机 */}
                 <div className={styles.headerPh}>
                <div>
                    <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                    <img onClick={(e)=>{this.goMark(e,1)}} className={styles.homeImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210910/3eaec71c4d3944acaaee4cc4a3a23a28.png'></img>
                </div>
                
                </div>
                <div className={styles.detail}>
                    <div className={styles.detailTop}>
                        <div className={styles.detailTitleLeft} onClick={()=>{this.back()}}>
                            <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/98245502259b4bed89d3b411fc91ee03.png'></img>
                        </div>
                        <div className={styles.detailTitleRight}>
                             <div>{contentObj.title}</div> 
                             <div>{contentObj.date}</div>
                        </div>
                    </div>
                    <div className={styles.detailContent} style={{display:imgList.length===1?'flex':'none',justifyContent:'center'}}>
                        <img alt='' src={imgList[0]} className={styles.oneImg}></img>  
                    </div>
                    <div className={styles.detailContent} style={{display:imgList.length>1?'flex':'none'}}>
                        <img className={styles.beforeImg} onClick={this.handlePrev} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/8d48eddff05248bebb4ba52664774c5d.png'></img>
                        <Carousel className={styles.carousel} ref='img' dots={false}>
                            {imgList.map((item,index)=><img alt='' src={item} key={index}></img>)}
                        </Carousel>
                        <img className={styles.beforeImg} onClick={this.handleNext} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/8868f904cb2a40b09b78abc0d6c13677.png'></img>
                    </div>
                    
                </div>   
            </div>
        )
    }
  
}

export default StoryDetail;