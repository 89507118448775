import React from 'react';
import styles from './partIntroduce.module.css';
import {introduceInfo,getSettingInfo} from '../api/index';
import backWUrl from '../img/backW.png';
import backYUrl from '../img/backY.png';

let wObj={
    0:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/85b5556c8e654ffd8eb19e56599691b8.png',
    1:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/23752d8e1168431b9c6b53c1fd9b0788.png',
    2:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/12204ac6162e4a989b863fc7aa2e4ddc.png',
    3:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/8ac44959127e4472ab96a3b100d72514.png',
    4:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/92c68082fa7f44789d9885742c904712.png',
    5:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e22934f30cda43158b38d5b0994a9f40.png',
   
};
let wObjT={
    0:'江湖世界',
    1:'游戏规则',
    2:'新手指导',
    3:'恶人谷',
    4:'矿山保卫战',
    5:'热门活动',
}
let yObj={
    0:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/4478cc510ecd4b15b41b49a4d30eeb5a.png',
    1:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e4cab2c8313e4fbcbba252e0789f0fdb.png',
    2:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/ceb8185d430840818d9c1b21387ddca6.png',
    3:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/4743426c166b4ef3a1248020f662e910.png',
    4:'https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/61f364a0807341abbb99780139f2e806.png', 
}
let yObjT={
    0:'玩赚尤礼',
    1:'玩法规则',
    2:'尤礼盲盒',
    3:'尤礼U·CLUB会员',
    4:'绿洲大陆',
}


class PartIntroduce extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         contentObj:{},
         flag:false,
         indexTitle:0,
        };
      }
      back=()=>{
        window.history.back()
    }
    goMark=(e,mark)=>{ 
        if(mark===1){
          this.props.history.push({ pathname: '/' });
        }else{
          this.props.history.push({ pathname: '/',state: { mark}  });
        }
    }
    introduceInfo=(id)=>{
        introduceInfo({id}).then(res=>{
            this.setState({contentObj:res})
          })
    }
    getSettingInfo=(type)=>{
        getSettingInfo({type}).then(res=>{
            this.setState({contentObj:res})
        })
    }
    componentDidMount() {
        const {state}=this.props.location;
        if(state){
            if(state.id){
                this.setState({flag:state.flag,indexTitle:state.indexTitle})
                this.introduceInfo(state.id);
            }
           else if(state.type){
               this.setState({flag:state.type===1?true:false})
                this.getSettingInfo(state.type);
           }
        }
        
    }

    render(){
        const{contentObj,flag,indexTitle}=this.state
        return(
            <div className={styles.content} >
                <div className={styles.top} style={{backgroundImage:`url(${flag?backYUrl:backWUrl})`}}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                        <div className={styles.headerContentTitle}>
                            <a onClick={(e)=>{this.goMark(e,1)}}>首页</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark1')}}>五行江湖</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark2')}}>尤礼</a>
                            <span>/</span>
                            <a onClick={(e)=>{this.goMark(e,'mark3')}}>关注我们</a>
                            <span>/</span>
                            </div>
                    </div>
                </div>
                    <div className={styles.titleImg}>
                        <img src={flag?yObj[indexTitle]:wObj[indexTitle]} alt=''></img>
                    </div>
                    <div className={styles.empty}></div>
                </div>
                {/* 手机 */}
                <div className={styles.headerPh}>
                <div>
                    <img className={styles.logoImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/e6110baa750e453eb64b77b97adfe37b.png'></img>
                    <img onClick={(e)=>{this.goMark(e,1)}} className={styles.homeImg} alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210910/3eaec71c4d3944acaaee4cc4a3a23a28.png'></img>
                </div>
                
                </div>
                <div className={styles.detail}>
                    <div className={styles.detailTitle}>
                        <div className={styles.detailTitleLeft} onClick={()=>{this.back()}}>
                            <img alt='' src='https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/98245502259b4bed89d3b411fc91ee03.png'></img>
                        </div>
                        <div className={styles.detailTitleRight}>
                            {flag?yObjT[indexTitle]:wObjT[indexTitle]}  
                        </div>
                        
                    </div>
                    <div className={styles.detailContent}  dangerouslySetInnerHTML = {{ __html: contentObj.content}} />
                    {/* <video id="media2" src='' autoplay></video> */}
                </div>
                
            </div>
        )
    }
  

}

export default PartIntroduce;