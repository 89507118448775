
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import { message } from 'antd'

// export const baseUrl = '//192.168.0.123:8082'; // 本地
export const baseUrl = 'https://api.goodluck.tuanxo.com'; // 线上
// export const baseUrl = 'http://116.62.66.0:9100'; // 测试

/**
 *
 *
 * @param {String} method Ajax请求类型 'POST'|'PUT'|'GET'|'DELETE'
 * @param {String} url 请求地址
 * @param {Object} params  参数
 * @returns Promise<T>
 */
function apiAxios(method, url, params, header = {
  contentType: 'application/json',
}) {
	
	return new Promise((resolve, reject) => {
		loadProgressBar()
    // config.headers.accessToken = localStorage.getItem('token')
		axios({
			method,
      url: baseUrl + url,
      headers: {
        'Content-Type': header.contentType,
        'token':localStorage.getItem('token')||''
      },
			data: method === 'POST' || method === 'PUT' ? params : null,
      params: method === 'GET' || method === 'DELETE' || method === 'PATCH' || url === '/backend/doLogin' || header.contentType === 'application/x-www-form-urlencoded' ? params : null,
			withCredentials: false
		})
			.then(res => {
				if (res.data.code === 999) {
					message.error('Token已过期')
					window.location.href = '/login'
				}
				if (res.status === 200) {
          if (res.data.code === 200) {
            resolve(res.data.result)
          } else {
            // message.error(res.data.message)
           
            switch (res.data.code) {
              case 400:
                message.error('请求错误')
                break
              case 404:
                message.error('请求错误,未找到该资源')
                console.log('请求错误,未找到该资源')
                break
              case 405:
                console.log('请求方法未允许')
                break
              case 408:
                console.log('请求超时')
                break
              case 500:
                message.error('服务器端出错')
                console.log('服务器端出错')
                break
              case 501:
                console.log('网络未实现')
                break
              case 502:
                console.log('网络错误')
                break
              case 503:
                console.log('服务不可用')
                break
              case 504:
                console.log('网络超时')
                break
              default:
                message.error(res.data.message)
            }
          }
				} else {
          message.error('Axios返回状态不对，查看请求处理过程．．．．')
				}
			}, err => {
        message.error('网络错误')
        reject(err)
			})
			.catch(err => {
        const errInfo = { 'err': err.response }
				reject(errInfo)
			})
	})
}
export default {
	get: (url, params, header) => {
		return apiAxios('GET', url, params, header)
	},
	post: (url, params, header) => {
		return apiAxios('POST', url, params, header)
	},
	put: (url, params, header) => {
		return apiAxios('PUT', url, params, header)
	},
	delete: (url, params, header) => {
		return apiAxios('DELETE', url, params, header)
	},
	patch: (url, params, header) => {
		return apiAxios('PATCH', url, params, header)
	}
}
