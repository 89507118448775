import React from 'react';
import './home.css'
import {getSettingInfo} from '../api/index';

class CompanyIntroduce extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         contentObj:{}
        };
      }

      getSettingInfo=(type)=>{
        getSettingInfo({type}).then(res=>{
            this.setState({contentObj:res})
          })
        }
    componentDidMount() {
       this.getSettingInfo(this.props.location.state.type)
    }

    render(){
        const{contentObj}=this.state
        return(
            <div>
                <div></div>
                <div>
                    <div dangerouslySetInnerHTML = {{ __html: contentObj.content}} />
                </div>
                
            </div>
        )
    }
  

}

export default CompanyIntroduce;