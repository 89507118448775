
import './App.css';
import Home from './components/home';
import NoticeMore from './components/noticeMore';
import NoticeDetail from './components/noticeDetail';
import CompanyIntroduce from './components/companyIntroduce';
import PartIntroduce from './components/partIntroduce';
import StoryMore from './components/storyMore';
import StoryDetail from './components/storyDetail';
import { BrowserRouter as Router, Route,Switch} from 'react-router-dom';
import { createHashHistory } from "history";
const history = createHashHistory();

function App() {
  return (
    // <div className="App">
    //   <Home></Home>
    // </div>
    <Router history={history}>
      <Switch>

      <Route path="/" exact component={Home}></Route>
      <Route path="/noticeMore" component={NoticeMore}></Route>
      <Route path="/noticeDetail" component={NoticeDetail}></Route>
      <Route path="/companyIntroduce" component={CompanyIntroduce}></Route>
      <Route path="/partIntroduce" component={PartIntroduce}></Route>
      <Route path="/storyMore" component={StoryMore}></Route>
      <Route path="/storyDetail" component={StoryDetail}></Route>

      </Switch>
     
    </Router>
  );
}

export default App;
